@import "~antd/dist/antd.css";
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.reportHeader {
  color: gray;
  /* background: blue; */
}

/* @media print {
    @page {
      margin: 2.5cm;
      page-break-inside: auto;
    }
    div.row > div {
      display: inline-block;
      border: solid 1px #ccc;
      margin: 0.2cm;
    }
    div.row {
      display: block;
    }
}


.table {
    display: table;
    border-spacing: 2px;
}
.row {
    display: table-row;
}
.row > div {
    display: table-cell;
    border: solid 1px #ccc;
    padding: 2px;
} */
@media print {
  table,
  th,
  td {
    border-collapse: collapse;
    border: 1px solid black;
    width: 100%;
    text-align: right;
  }
}
@media print {
  body {
    /* margin: 25mm 0mm 25mm 0mm; */
    margin: 0;
    color: #000;
    background: white;
    font-size: 12pt;
    background-color: #fff;
    page-break-inside: auto;
  }
  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }
  tfoot {
    display: table-header-group;
  }
  table {
    -fs-table-paginate: paginate;
  }
  @page {
    size: A4;
  }
  .ant-menu,
  .printBtn {
    display: none;
  }
  .react-bootstrap-table,
  body,
  html,
  table {
    page-break-inside: avoid;
    break-inside: avoid;
    page-break-before: always;
    break-before: always;
  }
}
#LoginForm {
  text-align: left;
}
#LoginForm .ant-form-item-required::before {
  display: none;
}
#LoginForm .ant-form-item,
#LoginForm label {
  margin-bottom: 0 !important;
}
.themedButton {
  background-color: #e08036;
  border-color: #e08036;
}
.themedButton:hover,
.themedButton:focus {
  background-color: #db7323;
  border-color: #db7323;
}
.brown-themedButton {
  background-color: #CEAF41 !important;
  border-color: #CEAF41 !important;
}
.brown-themedButton:hover,
.brown-themedButton:focus {
  background-color: #CEAF41 !important;
  border-color: #CEAF41 !important;
}
.allSurveys--card,
.ant-card-actions > li {
  text-align: left;
}
.ant-card-actions {
  padding-left: 24px;
}
.allSurveys--card .ant-card-meta-title {
  overflow: visible;
  white-space: normal;
  text-overflow: inherit;
  text-align: left;
}
.allSurveys--card:hover {
  cursor: pointer;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
}
.custom-table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #cbcbcb;
}
.custom-table td {
  border-bottom: 1px solid #cbcbcb;
  border-left: 1px solid #cbcbcb;
}
.custom-table th {
  /* border-top: 1px solid #cbcbcb;
  border-left: 1px solid #cbcbcb;
  border-right: 1px solid #cbcbcb; */
  border: 1px solid #cbcbcb;
  border-width: 1px 1px 2px 1px;
}
.custome-table th {
  padding: 5px;
  text-align: left;
  border: 1px solid #e8e8e8;
}
.custome-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #e8e8e8;
}
.custome-table th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 5;
  background: #fafafa;
}
.custome-table td {
  z-index: 2;
}
.withTable .ant-card-body {
  overflow: scroll;
  zoom: 0.8;
  padding: 0rem;
}
body {
  background-color: #fbfbfb !important;
}
.ant-btn-primary {
  background-color: #6ECA4B !important;
  border-color: #6ECA4B !important;
}
.ant-btn-default{
  background: rgba(110, 202, 75, 0.1);
  border: 1px solid #6ECA4B;
  color: #6ECA4B;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #FCF1C8;
}

.ant-menu-dark .ant-menu-item>a, .ant-menu-dark .ant-menu-item, .anticon-user > svg{
  color: #4F4F4F;
}
.ant-badge-count{
  background-color: #6ECA4B !important;
}
.ant-tabs-nav .ant-tabs-tab-active, .ant-tabs{
  color: #585858;
}
/* .ant-btn-primary {
  background-color: #434343 !important;
  border-color: #434343 !important;
} */
.ant-tabs-tab .ant-badge.ant-badge-not-a-wrapper {
  display: none;
}
.ant-tabs-tab-active .ant-badge.ant-badge-not-a-wrapper {
  display: inline-block;
  margin-left: 5px;
}
.outwardArrow {
  display: inline-flex;
  margin-left: 0.3rem;
  color: #585858;
  transform: rotate(45deg);
}
.outwardArrow:hover {
  display: inline-flex;
  margin-left: 0.3rem;
  color: #585858;
  cursor: pointer;
}
.ant-select-selection:hover .ant-input-suffix{
visibility: hidden;
}

.ant-menu-dark .ant-menu-item:hover > a{
  color: #1890ff;
}
.ant-tabs-ink-bar{
  background-color: #6ECA4B;
}
/* Dnd */

#dnd-table tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

#dnd-table tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.ant-select-selection:hover .ant-input-suffix {
  visibility: hidden;
}

.active {
  border-bottom: 3px solid #6ECA4B;
  font-weight: 500;
  /* color: white !important; */
}
.gm-fullscreen-control {
  display: none;
}
